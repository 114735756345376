import React from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { string, func, node, oneOfType } from 'prop-types';

import DialogHeader from './common/header';

function Confirmation({ title, onCancel, cancellationText, message, onConfirm, confirmationText, confirmationClassName, icon }) {
  return (
    <div className="confirmation-modal">
      <div className="ngdialog-overlay" />
      <div className="pbb-modal">
        <div className="pbb-modal__dialog pbb-modal--dialog-centered">
          <div className="pbb-modal__content pbb-modal__content--md">
            <DialogHeader close={onCancel} title={title} icon={icon} />
            <div className="pbb-modal__body">
              <div className="pbb-modal__body-wrapper">
                <div className="pbb-alert">
                  <span dangerouslySetInnerHTML={{ __html: message }} />
                </div>
              </div>
            </div>
            <div className="pbb-modal__footer">
              {onCancel && (
                <button className="pbb-btn pbb-btn--inverted" type="button" onClick={onCancel}>
                  {cancellationText}
                </button>
              )}
              {onConfirm && (
                <button className={classNames('pbb-btn', confirmationClassName)} type="button" onClick={onConfirm}>
                  {confirmationText}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Confirmation.propTypes = {
  title: string.isRequired,
  message: string.isRequired,
  cancellationText: string,
  confirmationText: string,
  confirmationClassName: string,
  onCancel: func,
  onConfirm: func,
  icon: oneOfType([string, node]),
};

Confirmation.defaultProps = {
  cancellationText: undefined,
  confirmationText: undefined,
  confirmationClassName: undefined,
  onCancel: undefined,
  onConfirm: undefined,
  icon: undefined,
};

function inPortal(props) {
  return createPortal(<Confirmation {...props} />, document.body);
}

export default inPortal;
