import React, { useEffect } from 'react';
import { string } from 'prop-types';

import { MaintenancePage } from '@pelckmans/business-components/components/maintenance-page';

import { useTranslation } from 'react-i18next';
import { toggleLoadingScreen } from '../utils/loadingScreen';
import useQueryParams from '../hooks/useQueryParams';

function Maintenance({ title, message }) {
  const { t } = useTranslation();

  const queryParams = useQueryParams();

  useEffect(() => {
    toggleLoadingScreen();
  }, []);

  return (
    <MaintenancePage
      translations={{
        title,
        message,
      }}
      action={t('errors.maintenance.tryAgain')}
      redirectUrl={queryParams.get('redirectUrl')}
    />
  );
}

Maintenance.propTypes = {
  title: string.isRequired,
  message: string.isRequired,
};

export default Maintenance;
