import classNames from 'classnames';
import { bool, func, number, oneOf, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserSlideSet, duplicateSlideSet } from '../../../../../../actions/slideSets';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import SlideSetStates from '../../../../../../enums/slideSetState';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { getCurrentModuleId } from '../../../../../../selectors/digibooks';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import Confirmation from '../../../dialogs/confirmation';

export default function ActionMenu({ isActive, slideSet, nodeId, setActiveActionMenu }) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { name, id } = slideSet;
  const [confirmationShown, setConfirmationShown] = useState(false);
  const analytics = useAnalytics();
  const moduleId = useSelector(getCurrentModuleId);

  const handleDelete = () => {
    dispatch(deleteUserSlideSet(slideSet));
    setConfirmationShown(false);
  };

  const logDuplicateEvent = slideSetId => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_DUPLICATED,
      objectId: slideSetId.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  };

  const handleDuplicate = async () => {
    const duplicatedSlideSet = await dispatch(duplicateSlideSet(slideSet, nodeId));

    logDuplicateEvent(duplicatedSlideSet.id);
    setActiveActionMenu(undefined);

    return window.open(buildDoceoUrl(duplicatedSlideSet, true), '_blank', 'noopener,noreferrer');
  };

  const logPresentEvent = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_VIEWER_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: moduleId,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  };

  const logEditModeEvent = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_EDIT_MODE_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  };

  const logOverviewEvent = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_OVERVIEW_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS,
    });
  };

  return (
    <>
      <div className="pbb-action-wrapper">
        <div className={classNames('pbb-action-menu', { active: isActive })} data-testid="action-menu">
          <div className="pbb-action-menu__triangle" data-testid="triangle" />
          <div className="pbb-action-menu__container">
            <a
              href={buildDoceoUrl(slideSet, undefined, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL_OPTIONS)}
              onClick={logPresentEvent}
              target="_blank"
              rel="noopener noreferrer"
              title={`${name} ${t('drawer.slideSets.actionMenu.present').toLowerCase()}`}
              className="pbb-action-menu__action"
            >
              <i role="presentation" className="icon-bb-presentmode icon-fw" />
              <span className="pbb-action-menu__action__text">{t('drawer.slideSets.actionMenu.present')}</span>
              <i role="presentation" className="icon-bb-opentab icon-fw" />
            </a>
            <div className="pbb-action-menu__divider" />
            <a
              href={buildDoceoUrl(slideSet, false)}
              target="_blank"
              rel="noopener noreferrer"
              title={`${name} ${t('drawer.slideSets.actionMenu.overview').toLowerCase()}`}
              className="pbb-action-menu__action"
              onClick={logOverviewEvent}
            >
              <i role="presentation" className="icon-bb-overview icon-fw" />
              <span className="pbb-action-menu__action__text">{t('drawer.slideSets.actionMenu.overview')}</span>
            </a>
            {slideSet.userId && (
              <>
                <a
                  href={buildDoceoUrl(slideSet, true)}
                  onClick={logEditModeEvent}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`${name} ${t('drawer.slideSets.actionMenu.edit').toLowerCase()}`}
                  className="pbb-action-menu__action"
                >
                  <i role="presentation" className="icon-bb-edit icon-fw" />
                  <span className="pbb-action-menu__action__text">{t('drawer.slideSets.actionMenu.edit')}</span>
                </a>
                <button
                  onClick={() => setConfirmationShown(true)}
                  type="button"
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`${name} ${t('actions.delete').toLowerCase()}`}
                  className="pbb-action-menu__action"
                >
                  <i role="presentation" className="icon-bb-modal-delete icon-fw" />
                  <span className="pbb-action-menu__action__text">{t('actions.delete')}</span>
                </button>
              </>
            )}
            <button
              type="button"
              className="pbb-action-menu__action"
              onClick={handleDuplicate}
              disabled={slideSet.state !== 'PUBLISHED'}
              title={`${name} ${t('drawer.slideSets.actionMenu.duplicate').toLowerCase()}`}
            >
              <i role="presentation" className="icon-bb-duplicate-slide icon-fw" />
              <span className="pbb-action-menu__action__text">{t('drawer.slideSets.actionMenu.duplicate')}</span>
            </button>
          </div>
        </div>
      </div>
      {confirmationShown && (
        <Confirmation
          title={`${t('actions.delete')} ${name}`}
          icon="icon-bb-modal-delete"
          message={t('drawer.slideSets.actionMenu.deleteMessage')}
          confirmationText={t('actions.delete')}
          confirmationClassName="pbb-btn--danger"
          cancellationText={t('confirmation.cancel')}
          onConfirm={handleDelete}
          onCancel={() => setConfirmationShown(false)}
        />
      )}
    </>
  );
}

ActionMenu.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    versionId: number.isRequired,
    state: oneOf(Object.values(SlideSetStates)),
  }).isRequired,
  isActive: bool.isRequired,
  nodeId: string.isRequired,
  setActiveActionMenu: func.isRequired,
};
