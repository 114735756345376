import classNames from 'classnames';
import { bool, func, number, shape, string } from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ANALYTICS_EVENT_SUBLOCATIONS, ANALYTICS_EVENT_TYPES } from '../../../../../../enums/analytics';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import useClickOutside from '../../../../../../hooks/useClickOutside';
import { getCurrentModuleId } from '../../../../../../selectors/digibooks';
import { buildDoceoUrl } from '../../../../../../utils/buildDoceoUrl';
import ShareButton from '../../../buttons/shareButton';
import ActionMenu from './ActionMenu';

function ReviewBadge() {
  const [t] = useTranslation();

  return (
    <div className="pbb-label pbb-label--alert pbb-list__label" data-testid="review-label">
      <span>{t('drawer.slideSets.states.review')}</span>
    </div>
  );
}

export default function SlideSetListItem({ slideSet, isActive, setActiveActionMenu, nodeId }) {
  const { id, name, state, versionId } = slideSet;

  const actionMenuRef = useRef(null);
  const analytics = useAnalytics();
  const moduleId = useSelector(getCurrentModuleId);

  useClickOutside(actionMenuRef, isActive ? () => setActiveActionMenu(undefined) : () => false);

  const handleAnalytics = () => {
    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.SLIDE_SET_VIEWER_ACCESSED,
      objectId: id.toString(),
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });

    analytics.capture({
      eventType: ANALYTICS_EVENT_TYPES.MODULE_ACCESSED,
      objectId: moduleId,
      subLocationId: ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL,
    });
  };

  const handleActiveActionMenu = () => {
    if (isActive) {
      setActiveActionMenu(undefined);
    } else setActiveActionMenu(versionId);
  };

  return (
    <li className="pbb-list__item" data-testid={`slideSetItem-${name}`}>
      <div className="pbb-list__link-wrap">
        <a
          onClick={handleAnalytics}
          className="pbb-list__link pbb-flex-wrap"
          title={name}
          href={buildDoceoUrl(slideSet, undefined, ANALYTICS_EVENT_SUBLOCATIONS.SIDEPANEL)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="pbb-flex">
            {slideSet.userId ? (
              <i role="presentation" className="pbb-list__link-icon icon-bb-presentmode-user" />
            ) : (
              <i role="presentation" className="pbb-list__link-icon icon-bb-presentmode" />
            )}
            <span className="pbb-list__link-text">{name}</span>
          </div>
          {state === 'REVIEW' && (
            <div className="pbb-list__labels">
              <ReviewBadge />
            </div>
          )}
        </a>
        <div className="pbb-list__actions" ref={actionMenuRef}>
          <ActionMenu isActive={isActive} slideSet={slideSet} setActiveActionMenu={setActiveActionMenu} nodeId={nodeId} />
          <ShareButton className={classNames('pbb-list__action', { active: isActive })} onClick={handleActiveActionMenu} />
        </div>
      </div>
    </li>
  );
}

SlideSetListItem.propTypes = {
  slideSet: shape({
    id: number.isRequired,
    name: string.isRequired,
    state: string.isRequired,
    versionId: number.isRequired,
  }).isRequired,
  isActive: bool.isRequired,
  setActiveActionMenu: func.isRequired,
  nodeId: string.isRequired,
};
