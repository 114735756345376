import { fabric } from 'fabric';
import { TRANSPARENT } from '../../../constants/constants';
import FabricTypes from '../../../enums/fabrictype';

const EDIT_BACKGROUND_COLOR = 'rgba(255, 255, 255, 0.6)';
const EDIT_BORDER_COLOR = 'rgba(194, 210, 214, 1)';

export const textBoxObjectTypes = {
  HANDLEBAR: 'HANDLEBAR',
  PLACEHOLDER: 'PLACEHOLDER',
  RESIZE: 'RESIZE',
};

/**
 * Measures the actual width of the textbox without text flowing.
 * We use this to auto scale the textbox when it has not been resized manually.
 * @param {@import('fabric').Textbox} textBox
 */
function measureTextboxWidthFor(textBox) {
  const measuringObject = new fabric.IText(textBox.text, {
    fontSize: textBox.fontSize,
    fontFamily: textBox.fontFamily,
  });

  /**
   * Padding gets added so text doesn't start flowing while typing.
   */
  return measuringObject.width + textBox.padding;
}

/**
 * Calculates the current text height for a textBox.
 * @param {@import('fabric').Textbox} textBox
 */
function calculateTextHeight(textBox) {
  // eslint-disable-next-line no-underscore-dangle
  return textBox.__lineHeights.reduce((acc, curr) => acc + curr, 0);
}

function createHandleBar({ top, left, width, height }) {
  return new fabric.Rect({ top, left, width, height, fill: TRANSPARENT, hasBorders: false, hasControls: false, meta: textBoxObjectTypes.HANDLEBAR, hoverCursor: 'move' });
}

/**
 * Handlebars are used to move the textBox. They span the sides of the textbox.
 * @param {@import('fabric').Textbox} textBox
 * @param {@import('fabric').Group} resizeIcon
 * @param {@import('fabric').Text} placeHolder
 */
function createHandleBarsFor(textBox, resizeIcon, placeHolder) {
  if (textBox.handleBars) textBox.canvas.remove(...textBox.handleBars);

  const top = createHandleBar({
    top: textBox.top - textBox.padding,
    width: textBox.width,
    height: textBox.padding,
    left: textBox.left,
  });

  top.on('moving', () => {
    textBox.set({
      top: top.top + textBox.padding,
      left: top.left,
      borderColor: EDIT_BORDER_COLOR,
    });

    placeHolder.set({
      top: top.top + textBox.padding,
      left: top.left,
    });

    resizeIcon.set({
      top: textBox.top + textBox.height,
      left: textBox.left + textBox.width,
    });
  });

  const left = createHandleBar({
    top: textBox.top - textBox.padding,
    width: textBox.padding,
    height: textBox.height + textBox.padding * 2,
    left: textBox.left - textBox.padding,
  });

  left.on('moving', () => {
    textBox.set({
      top: left.top + textBox.padding,
      left: left.left + textBox.padding,
      borderColor: EDIT_BORDER_COLOR,
    });

    placeHolder.set({
      top: left.top + textBox.padding,
      left: left.left + textBox.padding,
    });

    resizeIcon.set({
      top: textBox.top + textBox.height,
      left: textBox.left + textBox.width,
    });
  });

  const right = createHandleBar({
    top: textBox.top - textBox.padding,
    width: textBox.padding,
    height: textBox.height + textBox.padding,
    left: textBox.left + textBox.width,
  });

  right.on('moving', () => {
    textBox.set({
      top: right.top + textBox.padding,
      left: right.left - textBox.width,
      borderColor: EDIT_BORDER_COLOR,
    });

    placeHolder.set({
      top: right.top + textBox.padding,
      left: right.left - textBox.width,
    });

    resizeIcon.set({
      top: textBox.top + textBox.height,
      left: textBox.left + textBox.width,
    });
  });

  const bottom = createHandleBar({
    top: textBox.top + textBox.height,
    width: textBox.width,
    height: textBox.padding,
    left: textBox.left,
  });

  bottom.on('moving', () => {
    textBox.set({
      top: bottom.top - textBox.height,
      left: bottom.left,
      borderColor: EDIT_BORDER_COLOR,
    });

    textBox.set({
      top: bottom.top - textBox.height,
      left: bottom.left,
    });

    resizeIcon.set({
      top: textBox.top + textBox.height,
      left: textBox.left + textBox.width,
    });
  });

  const handleBars = [top, right, bottom, left];

  handleBars.forEach(handleBar => {
    handleBar.on('modified', opt => {
      if (opt.action === 'drag') textBox.fire('moved');
    });
  });

  textBox.set({ handleBars });

  textBox.canvas.add(...handleBars);
}

function roundedRectangle(ctx, { x, y, width, height, radius }) {
  const halfRadians = (2 * Math.PI) / 2;
  const quarterRadians = (2 * Math.PI) / 4;

  // top left arc
  ctx.arc(radius + x, radius + y, radius, -quarterRadians, halfRadians, true);

  // line from top left to bottom left
  ctx.lineTo(x, y + height - radius);

  // bottom left arc
  ctx.arc(radius + x, height - radius + y, radius, halfRadians, quarterRadians, true);

  // line from bottom left to bottom right
  ctx.lineTo(x + width - radius, y + height);

  // bottom right arc
  ctx.arc(x + width - radius, y + height - radius, radius, quarterRadians, 0, true);

  // line from bottom right to top right
  ctx.lineTo(x + width, y + radius);

  // top right arc
  ctx.arc(x + width - radius, y + radius, radius, 0, -quarterRadians, true);

  // line from top right to top left
  ctx.lineTo(x + radius, y);
}

export default function createRectWithText(annotation, textOptions, placeHolderText) {
  const textBox = new fabric.Textbox(annotation.text, {
    ...textOptions,
    meta: FabricTypes.RECTWITHTEXT,
    borderColor: TRANSPARENT,
    editingBorderColor: TRANSPARENT,
    objectCaching: false,
    hasControls: false,
    hasBorders: false,
    selectable: false,
    evented: true,
    charSpacing: 1,
  });

  // eslint-disable-next-line no-underscore-dangle
  textBox._renderBackground = function renderBackground(ctx) {
    // eslint-disable-next-line no-underscore-dangle
    const dim = this._getNonTransformedDimensions();

    ctx.fillStyle = this.backgroundColor;
    ctx.strokeStyle = this.borderColor;

    ctx.lineJoin = 'round';
    ctx.lineWidth = 5;
    ctx.beginPath();
    const options = {
      x: -dim.x / 2 - this.padding,
      y: -dim.y / 2 - this.padding,
      width: dim.x + this.padding * 2,
      height: dim.y + this.padding * 2,
      radius: 5,
    };
    roundedRectangle(ctx, options);
    ctx.closePath();

    ctx.fill();
    ctx.stroke();

    // if there is background color no other shadows
    // should be casted
    this._removeShadow(ctx); // eslint-disable-line no-underscore-dangle
  };
  /**
   * Textboxes do not have the ability to add a placeholder out of the box.
   */
  const placeHolder = new fabric.Text(placeHolderText, {
    meta: textBoxObjectTypes.PLACEHOLDER,
    ...textOptions,
    hasControls: false,
    hasBorders: false,
    selectable: false,
    evented: false,
    charSpacing: 1,
  });

  const resizeIcon = new fabric.Group(
    [
      new fabric.Path('m31.5 46.8c-.9 0-1.7-.3-2.4-1-1.3-1.3-1.3-3.5 0-4.8l10.3-10.4c1.3-1.3 3.5-1.3 4.8 0s1.3 3.5 0 4.8l-10.3 10.4c-.6.7-1.5 1-2.4 1z', {
        fill: EDIT_BORDER_COLOR,
      }),
      new fabric.Path('m17 44.7c-.9 0-1.7-.3-2.4-1-1.3-1.3-1.3-3.5 0-4.8l22.8-22.8c1.3-1.3 3.5-1.3 4.8 0s1.3 3.5 0 4.8l-22.8 22.8c-.7.7-1.5 1-2.4 1z', {
        fill: EDIT_BORDER_COLOR,
      }),
      new fabric.Path('m5.1 39c-.9 0-1.7-.3-2.4-1-1.3-1.3-1.3-3.5 0-4.8l31.1-31.1c1.3-1.3 3.5-1.3 4.8 0s1.3 3.5 0 4.8l-31.1 31.1c-.7.7-1.5 1-2.4 1z', {
        fill: EDIT_BORDER_COLOR,
      }),
    ],
    {
      objectCaching: false,
      hasControls: false,
      hasBorders: false,
      evented: true,
      hoverCursor: 'se-resize',
      meta: textBoxObjectTypes.RESIZE,
    },
  );

  resizeIcon.scaleToHeight(textBox.padding);
  resizeIcon.scaleToWidth(textBox.padding);

  textBox.on('added', () => {
    if (annotation.text.length === 0) {
      textBox.canvas.add(placeHolder);
      textBox.set({ width: placeHolder.width });

      return;
    }

    if (!textOptions.resized) {
      textBox.set({
        width: measureTextboxWidthFor(textBox),
      });
    } else {
      textBox.set({
        width: textOptions.width,
      });

      const textHeight = calculateTextHeight(textBox);

      textBox.set({
        height: textOptions.height > textHeight ? textOptions.height : textHeight,
        resizeHeight: textOptions.height,
      });
    }

    // brings the event coordinates up to date after setting width/height on an object.
    textBox.setCoords();
  });

  /**
   * Removed gets called whenever React rerenders the annotations.
   * This is where we trigger the callback to our useAnnotations hook.
   * We need to look for isChanged since empty text boxes have to be deleted.
   */
  textBox.on('removed', () => {
    textBox.canvas.set({ defaultCursor: 'default' });
    textBox.canvas.remove(...textBox.canvas.getObjects().filter(x => Object.values(textBoxObjectTypes).includes(x.meta)));
  });

  textBox.on('editing:entered', () => {
    if (!textBox.canvas) return;

    textBox.canvas.set({
      defaultCursor: 'crosshair',
    });

    textBox.canvas.setActiveObject(textBox);

    textBox.set({
      borderColor: EDIT_BORDER_COLOR,
    });

    if (textBox.backgroundColor === TRANSPARENT) {
      textBox.set({
        backgroundColor: EDIT_BACKGROUND_COLOR,
      });
    }

    // Set cursor to end of text.
    textBox.setSelectionStart(textBox.text.length);
    textBox.setSelectionEnd(textBox.text.length);

    resizeIcon.set({
      top: textBox.top + textBox.height,
      left: textBox.left + textBox.width,
    });

    createHandleBarsFor(textBox, resizeIcon, placeHolder);
    textBox.canvas.add(resizeIcon);
  });

  textBox.on('changed', () => {
    if (textBox.text.length > 0) textBox.canvas.remove(placeHolder);

    if (!textBox.resized) {
      // only change width if text is not empty (changed is also fired from renderAnnotations in fabric service)
      if (textBox.text.length > 0) {
        textBox.set({
          width: measureTextboxWidthFor(textBox),
        });
      }
    } else {
      // if text height exceeds the resizeheight, take text height.
      textBox.set({
        height: textBox.height > textBox.resizeHeight ? textBox.height : textBox.resizeHeight,
      });
    }

    resizeIcon.set({
      top: textBox.top + textBox.height,
      left: textBox.left + textBox.width,
    });

    placeHolder.set({
      top: textBox.top,
      left: textBox.left,
    });

    // brings the event coordinates up to date after setting width/height on an object.
    textBox.setCoords();
    resizeIcon.setCoords();
    placeHolder.setCoords();

    createHandleBarsFor(textBox, resizeIcon, placeHolder);
  });

  resizeIcon.on('moving', () => {
    textBox.fire('resizing');
    const newHeight = resizeIcon.top - textBox.top;
    const newWidth = resizeIcon.left - textBox.left;

    // we adjust the width of the textBox so we can calucate the width of the text.
    textBox.set({
      borderColor: EDIT_BORDER_COLOR,
      width: newWidth,
    });

    const isPlaceholderOnCanvas = textBox.canvas.getObjects().find(obj => obj === placeHolder);

    // eslint-disable-next-line no-underscore-dangle
    const { x: textWidth } = isPlaceholderOnCanvas ? placeHolder._getNonTransformedDimensions() : textBox._getNonTransformedDimensions();

    // if the width of the text doesn't allow us to resize any smaller we adjust the width accordingly
    const finalWidth = textWidth > newWidth ? textWidth : newWidth;

    // width and height have to be set separately due to implementation of fabric Textbox
    textBox.set({
      width: finalWidth,
    });

    textBox.set({
      height: newHeight,
      resizeHeight: newHeight,
    });

    const textHeight = calculateTextHeight(textBox);
    const finalHeight = textHeight > newHeight ? textHeight : newHeight;

    textBox.set({
      height: finalHeight,
      resizeHeight: finalHeight,
    });

    resizeIcon.set({
      left: textBox.left + textBox.width,
      top: textBox.top + textBox.height,
    });

    // brings the event coordinates up to date after setting width/height on an object.
    textBox.setCoords();
    resizeIcon.setCoords();
    textBox.resized = true;
  });

  resizeIcon.on('modified', opt => {
    if (opt.action === 'drag') {
      textBox.fire('resized', { height: textBox.height, width: textBox.width });
      textBox.enterEditing();
    }
  });

  return textBox;
}
