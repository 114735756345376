import React from 'react';
import classNames from 'classnames';
import { oneOf, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DrawerMode from '../../../../../enums/drawermode';
import { getFeatureToggleFor } from '../../../../../utils/feature-toggle';
import FeatureToggle from '../../../../../enums/feature-toggle';
import { getIsSlideSetsAccessEnabled } from '../../../../../selectors/slideSets';
import { getTeacherFeaturesEnabledFor } from '../../../../../selectors/digibooks';

const root = 'pbb-sidebar__drawer';

export const DrawerButtons = ({ activeDrawer, onClick, isOpen }) => {
  const isSlideSetsAccessEnabled = useSelector(getIsSlideSetsAccessEnabled);
  const teacherFeaturesEnabled = useSelector(getTeacherFeaturesEnabledFor);

  function isOpened(drawer) {
    return isOpen && activeDrawer === drawer;
  }
  const [t] = useTranslation();

  return (
    <div className={classNames(root)}>
      {teacherFeaturesEnabled && getFeatureToggleFor(FeatureToggle.WHITEPAGES) && (
        <button
          className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.WHITEPAGES) })}
          type="button"
          onClick={() => onClick(DrawerMode.WHITEPAGES)}
          title={t('drawer.buttons.whitepages.tooltip')}
        >
          <i className="pbb-sidebar__icon icon-bb-whitepage" data-testid="whitepages-button" />
        </button>
      )}
      {getFeatureToggleFor(FeatureToggle.NOTES) && (
        <button
          className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.NOTES) })}
          type="button"
          onClick={() => onClick(DrawerMode.NOTES)}
          title={t('drawer.buttons.notes.tooltip')}
        >
          <i className="pbb-sidebar__icon icon-bb-note" data-testid="notes-button" />
        </button>
      )}
      <button
        className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.MATERIAL) })}
        type="button"
        onClick={() => onClick(DrawerMode.MATERIAL)}
        title={t('drawer.buttons.material.tooltip')}
      >
        <i className="pbb-sidebar__icon icon-bb-file-media" data-testid="material-button" />
      </button>
      {getFeatureToggleFor(FeatureToggle.SLIDE_SETS) && isSlideSetsAccessEnabled && (
        <button
          className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.SLIDE_SETS) })}
          type="button"
          onClick={() => onClick(DrawerMode.SLIDE_SETS)}
          title={t('drawer.buttons.slideSets.tooltip')}
        >
          <i className="pbb-sidebar__icon icon-bb-presentmode" data-testid="material-button" />
        </button>
      )}
      <button
        className={classNames('pbb-sidebar__button', { 'pbb-sidebar__button--active pbb-sidebar__button--selected': isOpened(DrawerMode.TOC) })}
        type="button"
        onClick={() => onClick(DrawerMode.TOC)}
        title={t('drawer.buttons.toc.tooltip')}
      >
        <i className="pbb-sidebar__icon icon-bb-book" />
      </button>
    </div>
  );
};

DrawerButtons.propTypes = {
  activeDrawer: oneOf(Object.values(DrawerMode)),
  onClick: func.isRequired,
  isOpen: bool.isRequired,
};

DrawerButtons.defaultProps = {
  activeDrawer: undefined,
};

export default DrawerButtons;
